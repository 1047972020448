<template>
  <select v-model="selected">
    <option
      selected
      disabled
    >
      Video Encoder
    </option>
    <option value="x264">
      MPEG4 (x264)
    </option>
    <option value="x265">
      MPEG4 (x265)
    </option>
    <option value="libvpx">
      WebM (libvpx)
    </option>
    <option value="libtheora">
      OGV (theora)
    </option>
    <!-- <option value="av1">
      AV1 (Slow)
    </option> -->
  </select>        
</template>
<script>
export default {
    name: 'VideoCodecs',
    props: {
        value: {
          type: String,
          default: ''
        }
    },
  emits: ['input'],
  computed: {
    selected: {
      get() { return this.value; },
      set(v) { this.$emit('input', v); }
    }
  }  
}
</script>
<style scoped>
    select { width: 100%; }
</style>
<template>
  <header>
    <h1 class="small-heading">
      AVPress
    </h1>
    <button
      id="newButton"
      class="primary-btn"
      title="Create a new project. Clears the current workspace."
      @click="newProject"
    >
      New Project
    </button>
    <button
      id="mp4Demo"
      class="primary-btn demo"
      title="Load a demo MP4"
      @click="loadFile(null, null, ['./bunny.mp4','bunny.mp4', 'video/mp4'])"
    >
      Demo (2MB MP4)
    </button>
    <button
      id="gifDemo"
      class="primary-btn demo"
      title="Load a demo GIF"
      @click="loadFile(null, null, ['./demo.gif','demo.gif', 'image/gif'])"
    >
      Demo (3MB GIF)
    </button>
  </header>
  <div
    id="av-container"
    class="ffmpeg"
  >
    <!-- Video preview of output -->
    <div id="av-main">
      <div id="videoContainer">
        <div v-if="isPlayableVideo()">
          <video
            loop
            :src="videoSrc || localSrc"
            controls
            width="600px"
            height="400px"
            ref="videoplayer"
          />
        </div>
        <div v-else-if="videoSrc.length > 0 || localSrc.length > 0">
          <img
            :src="videoSrc || localSrc"
            width="600"
            height="400"
            style="max-width: 100%; height: auto; max-height: 300px;"
          >
        </div>
        <div v-else>
          <div class="video-placeholder"></div>
        </div>
      </div>

      <!-- Metadata -->
      <filesizes
        :source-filesize="sourceFilesize"
        :output-filesize="outputFilesize"
      />

      <!-- Export / Save As video -->
      <div id="downloadContainer">
        <div v-if="videoSrc.length > 0">
          <a
            class="darkBtn"
            :href="videoSrc"
            :download="outFilename"
          >Export</a>
        </div>
      </div>

      <div id="debugmessages">
        <Progress
          :value="progress"
          @change="progress = $event.target.value"
          id="avprogress"
        />
                
        <logging
          ref="logging"
          :message="message"
        />
      <span id="author">Built with 💜 by <a
      target="_blank"
      href="https://twitter.com/addyosmani"
    >Addy Osmani</a>. 100% client-side. Powered by <a target="_blank" rel="noopener" href="https://github.com/ffmpegwasm/ffmpeg.wasm">FFMPEG.wasm</a>.</span>
    <span><a target="_blank" rel="noopener" href="LICENSE.html">License</a></span>
    
      </div>

    </div>

    <!-- end main -->
    <div id="av-gap" />

    <!-- Start tools -->
    <div id="av-tools">
      <div
        id="box1"
        class="box"
      >
        <ul class="tab1 active">
          <li class="field active">
            <div style="flex:1;">
              <button @click="fileUpload">
                Select Video/GIF
              </button>
              <label
                id="fileuploadlabel"
                ref="fileuploadlabel"
                class="hidden"
                for="fileupload"
              >
                Select video
              </label>
              <input
                id="fileupload"
                class="hidden"
                type="file"
                style="display: &quot;none&quot;"
                @change="loadFile($event.target.name, $event.target.files); fileCount = $event.target.files.length"
              >
            </div>
          </li>

          <li
            class="field"
            style="padding: 0px;"
          >
            <div class="tabs">
              <div
                class="tab active"
                open-tab="1"
              >
                Video Settings
              </div>
            </div>
          </li>

          <li class="field">
            <video-codecs
              v-model:value="config"
              @change="config = $event.target.value"
            />
          </li>
          <li class="field">
            <input
              id="video_constant"
              ref="videoconstant"
              type="radio"
              name="video_quality_or_bitrate"
              value="constant"
            >

            <video-constant-quality
              v-model:value="crf"
              @change="crf = $event.target.value"
            />
          </li>
          <li class="field">
            <input
              id="video_bitrate"
              ref="videobitrate"
              type="radio"
              name="video_quality_or_bitrate"
              value="bitrate"
              checked
            >

            <video-bitrate
              v-model:value="bv"
              @change="bv = $event.target.value"
            />
          </li>

          <li class="field">
            <preset
              v-model:value="preset"
              @change="preset = $event.target.value"
            />
          </li>
          <li class="field">
            <framerate
              v-model:value="framerate"
              @change="framerate = $event.target.value"
            />
          </li>
          <li class="field crop">
            <!-- <video-trim v-bind:starttime="trimStartTime"
                  v-bind:endtime="trimEndTime" v-on:change="console.log($event.target)"></video-trim> -->
            <span>Trim (start, end)</span>
            <label for="fstarttime">Start Time</label>
            <input
              id="fstarttime"
              v-model="trimStartTime"
              placeholder="00:00:00"
              type="text"
              name="fstarttime"
              style="max-width: 56px"
            >

            <label for="fendtime">End Time</label>
            <input
              id="fendtime"
              v-model="trimEndTime"
              placeholder="00:00:00"
              type="text"
              name="fendtime"
              style="max-width: 56px;"
            >
          </li>

          <li class="field crop">
            <span>Resize (w,h)</span>
            <label for="fresizew">Resize W</label>
            <input
              id="fresizew"
              v-model="resizeW"
              placeholder="w"
              type="text"
              name="fresizew"
            >

            <label for="fcroph">Resize H</label>
            <input
              id="fresizeh"
              v-model="resizeH"
              placeholder="h"
              type="text"
              name="fresizeh"
            >
          </li>
          <li class="field crop">
            <!-- <crop v-bind:cropW="cropW"
                  v-bind:cropH="cropH"
                  v-bind:cropX="cropX"
                  v-bind:cropY="cropY"></crop> -->
            <span>Crop (w,h,x,y)</span>
            <label for="fcropw">Crop W</label>
            <input
              id="fcropw"
              v-model="cropW"
              placeholder="w"
              type="text"
              name="fcropw"
            >

            <label for="fcroph">Crop H</label>
            <input
              id="fcroph"
              v-model="cropH"
              placeholder="h"
              type="text"
              name="fcroph"
            >

            <label for="fcropx">Crop X</label>
            <input
              id="fcropx"
              v-model="cropX"
              placeholder="x"
              type="text"
              name="fcropx"
            >

            <label for="fcropy">Crop Y</label>
            <input
              id="fcropy"
              v-model="cropY"
              placeholder="y"
              type="text"
              name="fcropy"
            >
          </li>
          <li
            class="field"
            style="padding:0px;"
          >
          
            <div class="tabs" v-if="showAudio">
              <div
                class="tab active"
                open-tab="1"
              >
                Audio Settings
              </div>
            </div>
          </li>
          <li class="field" v-if="showAudio">
            <audio-codecs
              v-model:value="audio"
              @change="audio = $event.target.value"
            />
          </li>
          <li class="field" v-if="showAudio">
            <audio-bitrate
              v-model:value="ba"
              @change="ba = $event.target.value"
            />
          </li>
        
		
          <!-- TODO: Decide whether to expose parameters to users -->
          <li
            class="field"
            style="display:none;"
          >
            <textarea v-model="ffmpegParams" />
          </li>
		
          <li class="field">
            <button @click="encode">
              Encode
            </button>
          </li>
          <li class="field">
            <!-- TODO: Decide whether to expose parameters to users -->
            <div
              class="typography"
              hidden
            >
              <!-- FFMPEG arguments being run -->
              $ ffmpeg {{ ffmpegParams.replace('"', '').split(',').join(' ') }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Tools -->

    <div id="av-gap-2" />
  </div>
  <footer>
  </footer>
</template>
<script>
    import {
        createFFmpeg,
        fetchFile
    } from '@ffmpeg/ffmpeg';
    let ffmpeg = null;

    import Filesizes from './Filesizes.vue';
    import AudioCodecs from './AudioCodecs.vue';
    import VideoCodecs from './VideoCodecs.vue';
    import AudioBitrate from './AudioBitrate.vue';
    import Logging from './Logging.vue';
    import Framerate from './Framerate.vue';
    import Preset from './Preset.vue';
    // import Crop from './Crop.vue';
    import VideoConstantQuality from './VideoConstantQuality.vue';
    import Progress from './Progress.vue';
    import VideoBitrate  from './VideoBitrate.vue';
    // import VideoTrim from './VideoTrim.vue';

    const readFromBlobOrFile = (blob) => (
        new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = ({
                target: {
                    error: {
                        code
                    }
                }
            }) => {
                reject(Error(`File could not be read! Code=${code}`));
            };
            fileReader.readAsArrayBuffer(blob);
        })
    );

    const getDuration = (videoPlayer) => {
        if (videoPlayer === undefined) return `00:00:00`;
        const duration = videoPlayer.duration;
        let hours = parseInt(duration/3600, 10);
        let minutes = parseInt((duration % 3600)/60, 10);
        let seconds = parseInt(duration % 60, 10);
        if((`${hours}`).length==1)hours=`0${hours}`;
        if((`${minutes}`).length==1)minutes=`0${minutes}`;
        if((`${seconds}`).length==1)seconds=`0${seconds}`;
        return `${hours}:${minutes}:${seconds}`;
    }

    export default {
        name: 'FFMPEG',
        components: {
            Filesizes,
            AudioCodecs,
            AudioBitrate,
            Logging,
            Framerate,
            Preset,
            // Crop,
            Progress,
            VideoConstantQuality,
            VideoBitrate,
            VideoCodecs,
            // VideoTrim
        },
        data() {
            return {
                // SAB compatibility check
                IS_COMPATIBLE: typeof SharedArrayBuffer === 'function',

                // Sources for <video> previews
                videoSrc: '',
                localSrc: '',
                previewType: '', // video or image

                progress: 0,
                args: '',
                outFilename: '',
                inFilename: '',
                mediaType: '',
                message: '',

                // FFMPEG parameters 
                config: 'x264', // Video Codec
                audio: 'libmp3lame', // 'Audio Codec'
                bv: '1500', // Video Bitrate
                crf: '0',  // Video constant quality
                ba: '128', // Audio Codec
                framerate: 'Frame-rate (default)',
                preset: 'Preset',
                cropW: '0', 
                cropH: '0',
                cropX: '0',
                cropY: '0',
                resizeW: '0',
                resizeH: '0',
                trimStartTime: '00:00:00',
                trimEndTime: '00:00:00',

                // Encoder export
                encoderFile: [],
                encoderName: '',
                
                // File-sizes
                sourceFilesize: 0,
                outputFilesize: 0,

                // Visibility of optional controls
                showAudio: true,

                // Format "presets" collecting args, in/out and mediaType
                CONFIGS: {
                    x264: {
                        "args": "-c:v,libx264",
                        "inFilename": "video.avi",
                        "outFilename": "video.mp4",
                        "mediaType": "video/mp4"
                    },
                    x265: {
                        "args": "-c:v,libx265,-pix_fmt,yuv420p10le",
                        "inFilename": "video.avi",
                        "outFilename": "video.mp4",
                        "mediaType": "video/mp4"
                    },
                    libvpx: {
                        "args": "-c:v,libvpx",
                        "inFilename": "video.avi",
                        "outFilename": "video.webm",
                        "mediaType": "video/webm"
                    },
                    av1: {
                        "args": "-c:v,libaom-av1,-strict,-2,",
                        "inFilename": "video.avi",
                        "outFilename": "video.mp4",
                        "mediaType": "video/mp4"
                    },
                    libtheora: {
                        "args": "-c:v,libtheora,-strict,-2,",
                        "inFilename": "video.avi",
                        "outFilename": "video.ogv",
                        "mediaType": "video/ogg"
                    },
                }
            }
        },
         computed: {
            ffmpegParams() {
                return `${this.bv !== '0' && this.isSettingVideoBitrate()? '-b:v,' + this.bv +'k,' :''}` +
                       `${this.crf !== '0' && this.isSettingVideoConstantQuality()? ',-crf,' + this.crf + ',': ''}` +
                       `${this.args}` +
                       `${this.preset !== 'Preset'? ',-preset,' + this.preset : ''}` +
                       `${this.framerate !== 'Frame-rate (default)'? ',-filter:v,fps=fps=' + this.framerate : ''}` +
                       `${this.audio !== 'Audio Codec'? ',-c:a,' + this.audio : ''}` +
                       `${this.ba !== '0'? ',-b:a,' + this.ba +'k,' :''}` +
                       `${!this.isResizeEmpty() ? (',-vf,scale=' + this.resizeW + ':' + this.resizeH) : ''}` +
                       `${!this.isTrimEmpty() ? (',-ss,' + this.trimStartTime + ',-to,' + this.trimEndTime) : ''}` +
                       `${!this.isCropEmpty() ? (',-filter:v,crop=' + this.cropW + ':' + this.cropH + ':' + this.cropX + ':' + this.cropY) : ''}`;
            }
        },
        watch: {
            config: function(newVal) {
                this.setConfig(newVal);
            }
        },
        mounted() {
            this.setConfig(this.config);

            // end values
            if (ffmpeg === null) {
                ffmpeg = createFFmpeg({
                    log: true,
                    // Re-enable for AV1
                    // https://github.com/ffmpegwasm/ffmpeg.wasm/issues/61
                    // https://unpkg.com/@ffmpeg/ffmpeg@0.9.8/dist/ffmpeg.min.js
                    // https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js
                    // We need to use the below for AV1
                    corePath: 'https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js'
                    
                });
            }

            // https://github.com/ffmpegwasm/ffmpeg.wasm/blob/9b8a5f7723dd38143602a259828c3d95eb49bcd0/src/utils/parseProgress.js
            const ts2sec = (ts) => {
              const [h, m, s] = ts.split(':');
              return (parseFloat(h) * 60 * 60) + (parseFloat(m) * 60) + parseFloat(s);
            };

            function getTimeFromFFMPEGLog(str) {
              return ts2sec(str.split('time=')[1].split(' bitrate')[0]);
            }

            ffmpeg.setLogger(({
                // type,
                message
            }) => {
                this.setMessage(message);

              // Get current time (e.g 00:00:07.86)
              if (message.indexOf("time=") >0 ) {
                // console.log(getTimeFromFFMPEGLog(message));
                this.$refs.videoplayer.currentTime = getTimeFromFFMPEGLog(message);
              }
            });
            ffmpeg.setProgress(({
                ratio
            }) => {
                if (ratio >= 0 && ratio <= 1) {
                    this.setProgress(ratio * 100);
                }
                if (ratio === 1) {
                    setTimeout(() => {
                        this.setProgress(0);
                    }, 1000);
                }
            });
        },
        methods: {
            async loadFile(fieldName, fileList, customPathConfig) {
                // Demos: Support loading from a specific local URL
                if (customPathConfig !== undefined) {
                    const demoFile = await this.getFileFromUrl(...customPathConfig);
                    fileList = [demoFile];
                    this.localSrc = customPathConfig[0];

                    // If a GIF demo was selected...
                    if (customPathConfig[1].includes('.gif')) {
                        this.previewType = 'image';
                        this.showAudio = false;
                    } else {
                        this.previewType = 'video';
                        this.showAudio = true;
                    }

                    // Scroll back to top from demos
                    window.scrollTo(0,0);
                }
                
                // File: read in as a Uint8Array
                const files = fileList;
                const file = new Uint8Array(await readFromBlobOrFile(files[0]));
                const {
                    name
                } = files[0];

                // File: store on the Vue data instance for later reuse
                this.encoderFile = file;
                this.encoderName = name;

                // If a GIF input was selected...
                if (this.encoderName.includes('.gif')) {
                    this.previewType = 'image';
                    this.showAudio = false;
                } else {
                    this.previewType = 'video';
                    this.showAudio = true;
                }

                // Logging: File selected and file size
                this.setMessage(`File selected: ${this.encoderName}`);
                const fsize = Math.round((files[0].size / 1024));
                this.sourceFilesize = fsize;
                this.setMessage(`File-size: ${fsize}KB`);

                // Player: set the video source to selected file
                this.setVideoTagLocalSrc(URL.createObjectURL(files[0], {
                    type: this.mediaType
                }));

                // Duration
                // this.trimEndTime = getDuration(this.$refs.videoplayer);
                // console.log(this.trimEndTime);

               // UI: Now that a media file is selected, enable disabled UI
               const fields = document.querySelectorAll('.field');
                [].forEach.call(fields, (div) => {
                    div.classList.add('active');
                });
            },
            async getFileFromUrl(url, name, defaultType = 'image/jpeg'){
                const response = await fetch(url);
                const data = await response.blob();
                return new File([data], name, {
                    type: response.headers.get('content-type') || defaultType,
                });
            },
            async encode() {
                const name = this.encoderName;
                const file = this.encoderFile;

                this.setMessage('Loading FFmpeg.wasm');
                if (ffmpeg === null) {
                    ffmpeg = createFFmpeg({
                        log: true,
                    });
                }
                if (!ffmpeg.isLoaded()) {
                    this.setMessage('Loading ffmpeg.wasm-core, may take few minutes');
                    await ffmpeg.load();
                }

                ffmpeg.FS('writeFile', name, await fetchFile(file));
                this.setMessage('Start to run command');
                const start = Date.now();
                const params = this.ffmpegParams;
                const runArgs =  params.replace('"', '').split(',');
                console.log('runArgs', runArgs);

                const ffmpegArgs = ['-i', name, ...runArgs, this.outFilename];
               
                await ffmpeg.run(...ffmpegArgs);

                this.setMessage(`Done in ${Date.now() - start} ms`);
              
                const data = ffmpeg.FS('readFile', this.outFilename);
                // Preview type is a video by default
                this.previewType = 'video';
                this.setVideoTagExportSource(URL.createObjectURL(new Blob([data.buffer], {
                    type: this.mediaType
                })));

                const fsize = Math.round((data.length / 1024));
                this.outputFilesize = fsize;
                this.setMessage(`File-size: ${fsize}KB`);
            },
            setMessage(message) {
                this.message += '\n' + message;
                // const textarea = this.$refs.logging;
                //textarea.scrollTop = textarea.scrollHeight;
                const textarea = this.$refs.logging;
                textarea.scrollToBottom();
            },
            setProgress(progress) {
                this.progress = progress;
            },
            setVideoTagExportSource(src) {
                this.videoSrc = src;
            },
            setVideoTagLocalSrc(src) {
                this.localSrc = src;
            },
            setConfig(selectedConfig) {
                const ffConfig = this.CONFIGS[selectedConfig];
                this.args = ffConfig.args;
                this.inFilename = ffConfig.inFilename;
                this.outFilename = ffConfig.outFilename;
                this.mediaType = ffConfig.mediaType;
            },
            fileUpload() {
                this.$refs.fileuploadlabel.click();
            },
            getCropConfig(w,h,x,y) {
                return ['-filter:v',`crop=${w}:${h}:${x}:${y}`];
            },
            getScaleConfig(w,h) {
                return ['-vf', `scale=${w}:${h}`];
            },
            getTrimConfig(start, stop) {
                return ['-ss', `${start}`, '-to', `${stop}`];
            },
            isCropEmpty() {
                if (this.cropX === '0' && this.cropY === '0' && this.cropW === '0' & this.cropH === '0') {
                    return true;
                } else {
                    return false;
                }
            },
            isResizeEmpty() {
                if (this.resizeW === '0' & this.resizeH === '0') {
                    return true;
                } else {
                    return false;
                }
            },
            isTrimEmpty() {
                if (this.trimStartTime === '00:00:00' & this.trimEndTime === '00:00:00') {
                    return true;
                } else {
                    return false;
                }
            },
            isPlayableVideo() {
                return (this.previewType === 'video') &&
                       (this.localSrc.length > 0) ||
                       (this.videoSrc.length > 0);
            },
            isSettingVideoConstantQuality() {
                return !!(this.$refs.videoconstant !== undefined && this.$refs.videoconstant.checked);
            },
            isSettingVideoBitrate() {
                return !!(this.$refs.videobitrate !== undefined && this.$refs.videobitrate.checked);
            },
            newProject() {
                // Clear environment
                Object.assign(this.$data, this.$options.data());
                this.setConfig('x264');
            }
        },
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

:root {
    --white: #fff;
    --black: #000;
    --dark-gray: #343436;
    --dark-gray-shadow: #444446;
    --box-background: #313133;
}

header {
  width: 100%;
  display: flex;
  border-top: 1px solid #252526;
  box-shadow: inset 0 1px 0 0 var(--dark-gray-shadow);
  background: var(--dark-gray);
  height: 36px;
}

.section-title {
    text-align: center;
    font-weight: 700;
    letter-spacing: -.05em;
    font-size: 2.75rem;
}

.small-heading {
    font-size: 1.4rem;
    letter-spacing: -.05em;
    margin: 6px 0 9px;
}

header h1 {
  font-size: 15px;
  padding-left: 14px;
  color: var(--white);
}

#av-container {
  display: grid;
  grid-template-columns: 70% 1% 28% 1%;
  grid-template-rows: 1.7fr 0fr;
  gap: 0px 0px;
  grid-template-areas: ". . ."
        ". . .";
  margin-top: 24px;
}

#author {
    padding: 16px;
    color: white;
    width: 95%;
    display: block;
    text-align: center;
}

@media only screen and (max-width: 640px) {
  #av-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1.7fr 0fr;
    gap: 0px 0px;
    grid-template-areas: initial;
  }
  #av-tools{
    display: flex;
  }
  #box1 {
    width: 100%;
    margin-top: 64px;
  }
  #av-tools {
    display: contents;
  }

  #mp4Demo, #gifDemo {
    font-size: 10px;
  }
  #author {
    display: none;
  }
}

@media only screen and (min-width: 641px) and (max-width: 960px) {
  #av-container {
    grid-template-columns: 52% 1% 28% 1%;
  }
}

#av-main {
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 4px;
}

#av-tools {
  align-items: stretch;
  overflow: hidden;
}

#av-tools .av-row {
  clear: both;
}

label,
    #fileupload {
    color: var(--foreground-color);
}

video,
#videoContainer {
  height: 300px;
  width: 100%;
  object-fit: contain;
  background-color: var(--black);
  margin: 0 auto;
  border: 1px solid #9e9e9e;
}

#av-tools {
  margin: 0 auto;
  justify-content: space-between;
}

#fileupload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.darkBtn {
  float: left;
  display: inline-block;
  background: var(--background-color);
  color: var(--foreground-color);
  font-size: 16px;
  padding: 5px 20px 5px 20px;
  font-weight: bold;
  border-radius: 3px;
  border: 1px solid var(--foreground-color);
  cursor: pointer;
}

.darkBtn:hover,
.config-select:hover {
  background: var(--background-highlight);
}

.hidden {
  display: none;
}

.preview {
  position: relative;
  text-align: left;
  box-sizing: border-box;
  padding: 0px;
  overflow: hidden;
  white-space: pre;
  font-family: monospace;
  color: rgb(156, 220, 254);
  background-color: var(--preview-background-color);
  font-size: 18px;
  margin-top: 8px;
  clear: both;
  border: 1px solid var(--white);
}

.config-select {
  background: var(--background-color);
  color: var(--foreground-color);
  padding: 5px 62px 5px 5px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid var(--white);
}

#debugmessages {
  width: 100%;
  height: 150px;
  bottom: 0;
  position: relative;
  color: var(--foreground-light);
  border-top: 1px solid var(--foreground-color);
  margin-top: 30px;
}

#avprogress {
  padding: 10px;
}

#downloadContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

#downloadContainer a {
  text-decoration: none;
  padding: 16px 128px 16px 128px;
  margin: 0 auto;
}

.message {
  padding-top: 20px;
}

p {
  position: absolute;
  margin: auto;
  left: 300px;
  top: 50px;
  width: 500px;
  font-size: 28px;
  line-height: 1.6;
}


.box {
  background: var(--box-background);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid var(--black);
  border-radius: 4px;
  color: var(--white);
  min-width: 250px;
  padding-top: 12px;
  box-shadow: inset 0 1px 0 0 #424244;
  right: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.box .close {
  position: absolute;
  margin: auto;
  right: 2px;
  top: 1px;
  height: 9px;
  width: 9px;
  color: #000;
  background: #454545;
  border-radius: 2px;
  border: 1px solid #252526;
  box-shadow: inset -2px 0 0 0 #454545, inset 2px 0 0 0 #454545, inset 0 -2px 0 0 #454545, inset 0 -3px 0 0 #8f8f8f;
  cursor: pointer;
}

.box .close.open {
  box-shadow: inset 0 0 0 2px #454545, inset 0 0 0 3px #8f8f8f;
}

.box .tabs {
  width: 100%;
  display: -webkit-box;
  display: flex;
  border-top: 10px solid #252526;
  box-shadow: inset 0 1px 0 0 #444446;
  background: #343436;
}

.box .tabs .tab {
  padding: 4px 10px;
  font-size: 14px;
  border-right: 1px solid #252526;
  border-bottom: 1px solid #252526;
  text-shadow: 0 -1px 0 #333333;
  color: #8f8f8f;
}

.box .tabs .tab.active {
  background: var(--box-ul-background);
  border-bottom: none;
  box-shadow: 0 1px 0 0 var(--box-ul-background), inset 0 1px 0 0 #646467;
  color: #fff;
}

.box .tabs .tab:not(.active):hover {
  background: #454545;
  box-shadow: inset 0 1px 0 0 #595959;
  cursor: pointer;
}

.box > ul {
  list-style: none;
  background: var(--box-ul-background);
  margin: 0;
  border-top: 1px solid #646467;
  box-shadow: 0 -1px 0 0 #252526;
  padding: 0;
  padding-top: 8px;
  display: none;
}

.box > ul.active {
  display: block;
}

.box > ul > li {
  background: #414141;
  border-bottom: 1px solid #343434;
  display: -webkit-box;
  display: flex;
  position: relative;
}

.box > ul > li:first-child {
  border-top: 1px solid #28282a;
}

.box > ul > li a {
  display: block;
  padding: 16px;
  color: #fff;
  text-shadow: 0 1px 0 #333333;
}

.box > ul > li.field {
  padding: 8px;
  -webkit-box-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: flex;
  background: var(--box-ul-background);
  box-shadow: inset 0 1px 0 0 #707070;
  position: relative;
}

.box > ul > li.field span {
  margin-right: 8px;
  text-shadow: 0 -1px 0 #000;
}

.box > ul > li.field span.font-size {
  text-shadow: -4px 4px 0 rgba(255, 255, 255, 0.4);
}

.box > ul > li.field select,
.box > ul > li.field input[type=text],
.box > ul > li.field textarea {
  max-width: 100%;
  -webkit-box-flex: 1;
  flex: 1;
  background: var(--box-formcontrol-background);
  color: #fff;
  border: 1px solid  var(--button-border-color);
  box-shadow: 0 1px 0 0 #696969;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;
  font-size: 13.33px;
  font-family: Arial, Helvetica, sans-serif;
}

.box > ul > li.field select {
  width: 100%;
}

.box > ul > li.field select:not(:last-child),
.box > ul > li.field input[type=text]:not(:last-child),
.box > ul > li.field textarea:not(:last-child) {
  margin-right: 8px;
}

.box > ul > li.field input[type=text] {
  max-width: 46px;
  border-radius: 4px;
}

.box > ul > li.field button {
  width: 100%;
  height: 36px;
}

.box > ul > li.field {
  opacity: 0.5;
  pointer-events: none;
}

.box > ul > li.field.active {
  opacity: 1;
  pointer-events: auto;
}

.box > ul > li.color ul {
  display: grid;
  list-style: none;
  padding: 0;
  height: 60px;
  grid-template-rows: 50% 50%;
  grid-auto-columns: auto;
  height: 100%;
  grid-auto-flow: column;
}

.box > ul > li.color ul li {
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
  text-indent: -999999px;
  display: block;
}

.box > ul > li.color ul li.active {
  box-shadow: inset 0 0 0 1px #3498db, inset 0 0 0 2px #196090;
}

.box > ul > li input[type=checkbox] {
  position: absolute;
  top: 0;
  visibility: hidden;
}

.box > ul > li input[type=checkbox]:checked + label {
  border: 1px solid  var(--button-border-color);
  background: var(--box-label-background);
  box-shadow: inset 0 1px 0 0 #333333, 0 1px 0 0 #6a6a6a;
}

.box > ul > li input[type=checkbox]:disabled + label {
  color: #868686;
}

.box > ul > li input[type=checkbox]:disabled + label:hover {
  background: transparent;
  box-shadow: none;
  border: 1px solid transparent;
  cursor: default;
}

.box > ul > li label {
  width: 25px;
  height: 25px;
  line-height: 27px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid transparent;
}

.box > ul > li label.italic {
  font-style: italic;
}

.box > ul > li label.underline {
  text-decoration: underline;
}

.box > ul > li label.line-through {
  text-decoration: line-through;
}

.box > ul > li label:not(:last-child) {
  margin-right: 4px;
}

.box > ul > li label:hover {
  border: 1px solid var(--button-border-color);
  background: #6c6c6c;
  box-shadow: inset 0 1px 0 0 #919191, 0 1px 0 0 #6a6a6a;
  cursor: pointer;
}

.box > ul > li label.active {
  border: 1px solid var(--button-border-color);
  background: var(--box-label-background);
}

.box.closed *:not(.close) {
  display: none;
}

.box .field fieldset {
  width: 100%;
}

.crop label {
  display: none;
}

button {
    max-width: 100%;
    flex: 1;
    background: var(--button-background-color);
    color: var(--button-foreground-color);
    border: 2px solid var(--button-border-color);
    box-shadow: #696969;
    position: relative;
    border-radius: 4px;
    font-size: 13.33px;
    font-family: Arial, Helvetica, sans-serif;
}

button:hover {
    background-color: var(--button-background-hover);
}

input[type="radio"]:not(:checked) + div {
    opacity: 0.5;
    pointer-events: none;
}

#debugmessages .message {
  background: var(--black);
  min-height: 100px;
  width: 100%;
  overflow-y: scroll;
  text-align: left;
  max-height: 150px;
}

span a {
  color: var(--white);
}

.primary-btn {
  max-width: 130px;
  margin-left: 12px;
  border-radius: 4px;
}

.primary-btn.demo {
  background-color: #68677c;
}

.primary-btn.demo:hover {
  background-color: #353450;
}

footer {
    width: 100%;
    display: block;
    /* background-image: url(/wave.svg); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 276px;
    bottom: 0;
    position: absolute;
    z-index: -1000;
}

#demos { margin-top: 150px; }
#demos .button-inset { display: block; border-radius: 100px; }
#demos .button-inset img { width: 80px; height: 80px; display: block; border-radius: 100px; }
#demos .button-inset span {
    color: var(--white);
    width: 100%;
    margin: .7rem auto 0;
    padding: .5rem 0.6rem;
    background: var(--background-pink);
    border-radius: 10px;
    word-break: break-word;
}
#demos button {
    cursor: pointer;
    background: none;
    border: none;
    font: inherit;
    padding: 0;
    margin: 0;
    height: 124px;
}
#demos button:hover img, #demos button:hover span {
    border: 3px solid var(--white);
}
#demos .demos-list {
    display: grid;
    gap: 3rem;
    justify-items: center;
    justify-content: center;
    padding: 0;
    list-style-type: none;
    grid-template-columns: repeat(auto-fit, 80px);
}

#demos h3 {
    color: var(--white);
}

.video-placeholder {
    background-image: url(/avpress-poster-optimized.svg);
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #4f45e3;
    background-position-y: center;
    background-position-x: center;
}

</style>
<template>
  <div v-if="selected !== 0">
    <span>{{ selected.toFixed(2) }} % </span>
    <progress
      id="file"
      max="100"
      :value="selected"
    >
      0%
    </progress>
  </div>     
</template>
<script>
export default {
    name: 'Progress',
    props: {
        value: {
          type: Number,
          default: 0
        }
    },
    emits: ['input'],
  computed: {
    selected: {
      get() { return this.value; },
      set(v) { this.$emit('input', v); }
    }
  }  
}
</script>
<style scoped>
</style>
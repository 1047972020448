<template>
  <div id="audio-bitrate">
    <fieldset>
      <legend>Audio Bitrate (Kbps)</legend>
      <select v-model="selected">
        <option
          selected
          disabled
        >
          Audio Bitrate (Kbps)
        </option>
        <option value="24">
          24
        </option>
        <option value="32">
          32
        </option>
        <option value="40">
          40
        </option>
        <option value="48">
          48
        </option>
        <option value="56">
          56
        </option>
        <option value="64">
          64
        </option>
        <option value="80">
          80
        </option>
        <option value="96">
          96
        </option>
        <option value="112">
          112
        </option>
        <option value="128">
          128
        </option>
        <option value="160">
          160
        </option>
        <option value="192">
          192
        </option>
        <option value="224">
          224
        </option>
        <option value="256">
          256
        </option>
        <option value="320">
          320
        </option>
      </select>
    </fieldset>
  </div>
</template>
<script>
export default {
  name: 'AudioBitrate',
  props: {
      value: {
        type: String,
        default: ''
      }
  },
  emits: ['input'],
  computed: {
    selected: {
      get() { return this.value; },
      set(v) { this.$emit('input', v); }
    }
  }  
}
</script>
<style scoped>
    #audio-bitrate { width: 90%; }
</style>
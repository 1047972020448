<template>
  <div>
    <div
      id="logging"
      style="width:100%;"
    >
      <div
        id="box2"
        class="box"
        style="width:100%;"
      >
        <div class="tabs">
          <div
            class="tab active"
            open-tab="1"
          >
            Logging
          </div>
        </div>
        <ul class="tab1 active">
          <li class="field active">
            <!-- FFMPEG logged messages -->
            <textarea
              ref="logging"
              class="message"
              :value="message"
            />

            <!-- Browser compatibility check -->
            <textarea
              v-if="!IS_COMPATIBLE"
              id="compatibility"
              class="message"
            >Your browser doesn't support SharedArrayBuffer. ffmpeg.wasm cannot execute.
                        
Please use the latest version of Chromium, Firefox or any other browser that supports SharedArrayBuffer.
                        </textarea>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'Logging',
    props: {
        message: {
          type: String,
          default: ''
        }
    },
    emits: ['input'],
    data() {
        return {
            IS_COMPATIBLE: typeof SharedArrayBuffer === 'function'
        }
    },
    methods: {
        scrollToBottom() {
            this.$refs.logging.scrollTop = this.$refs.logging.scrollHeight;
        }
    }
}
</script>
<style scoped>
    
</style>
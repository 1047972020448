<template>
  <div>
    <div id="metadata">
      <div
        v-if="sourceFilesize !== 0"
        class="metadata-row"
      >
        <span>Source: </span> 
        <span class="file-size">{{ sourceFilesize }}KB</span>
      </div>
      <div
        v-if="outputFilesize !== 0"
        class="metadata-row"
        style="justify-content: flex-end;"
      >
        <span>Output: </span> 
        <span class="file-size">{{ outputFilesize }}KB</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'Filesizes',
    props: {
        sourceFilesize: {
          type: Number,
          default: 0
        },
        outputFilesize: {
          type: Number,
          default: 0
        }
    },
    emits: ['input'],
}
</script>
<style scoped>

#metadata {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    color: white;
    padding-top: 8px;
}

#metadata .metadata-row { display: flex; }
#metadata .metadata-row span:first-child { 
    font-weight: bold; 
    margin-right: 2px; 
}
</style>
<template>
  <div id="video-constant-quality">
    <fieldset>
      <legend>Constant Quality</legend>
      <input
        id="fcrf"
        v-model="selected"
        type="range"
        name="fcrf"
        min="0"
        max="51"
      >
      &nbsp;<input
        v-model="selected"
        type="text"
        placeholder="0"
      >
    </fieldset>
  </div>
</template>
<script>
export default {
    name: 'VideoConstantQuality',
    props: {
        value: {
          type: String,
          default: ''
        }
    },
  emits: ['input'],
  computed: {
    selected: {
      get() { return this.value; },
      set(v) { this.$emit('input', v); }
    }
  }  
}
</script>
<style scoped>
#video-constant-quality { width: 84%; }
</style>
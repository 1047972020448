<template>
  <select v-model="selected">
    <option
      selected
      disabled
    >
      Frame-rate (default)
    </option>
    <option value="5">
      5
    </option>
    <option value="10">
      10
    </option>
    <option value="15">
      15
    </option>
    <option value="20">
      20
    </option>
    <option value="25">
      25 (PAL)
    </option>
    <option value="29.97">
      29.97 (NTSC)
    </option>
    <option value="30">
      30
    </option>
    <option value="60">
      60
    </option>
  </select>      
</template>
<script>
export default {
    name: 'Framerate',
    props: {
        value: {
          type: String,
          default: ''
        }
    },
  emits: ['input'],
  computed: {
    selected: {
      get() { return this.value; },
      set(v) { this.$emit('input', v); }
    }
  }  
}
</script>
<style scoped>
    select { width: 100%; }
</style>
<template>
  <div>
    <FFMPEG />
  </div>
</template>

<script>
import FFMPEG from './FFMPEG';

export default {
  name: 'FFMPEGContainer',
  components: {
    FFMPEG
  },
  data() {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, label, input[type="file" i] {
  color: var(--foreground-color);
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<template>
  <select v-model="selected">
    <option
      selected
      disabled
    >
      Preset
    </option>
    <option value="ultrafast">
      ultrafast
    </option>
    <option value="superfast">
      superfast
    </option>
    <option value="veryfast">
      veryfast
    </option>
    <option value="faster">
      faster
    </option>
    <option value="medium">
      medium
    </option>
    <option value="slow">
      slow
    </option>
    <option value="slower">
      slower
    </option>
    <option value="veryslow">
      veryslow
    </option>
  </select>     
</template>
<script>
export default {
    name: 'Preset',
    props: {
        value: {
          type: String,
          default: 'Preset'
        }
    },
  emits: ['input'],
  computed: {
    selected: {
      get() { return this.value; },
      set(v) { this.$emit('input', v); }
    }
  }  
}
</script>
<style scoped>
    select { width: 100%; }
</style>
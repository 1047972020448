<template>
  <select v-model="selected">
    <option
      selected
      disabled
    >
      Audio Codec
    </option>
    <option value="aac">
      AAC
    </option>
    <option value="ac3">
      AC3
    </option>
    <option value="libmp3lame">
      MP3
    </option>
    <option value="libvorbis">
      OGG/Vorbis
    </option>
    <option value="libopus">
      Opus
    </option>
  </select>
</template>
<script>
export default {
  name: 'AudioCodecs',
  props: {
      value: {
        type: String,
        default: ''
      }
  },
  emits: ['input'],
  computed: {
    selected: {
      get() { return this.value; },
      set(v) { this.$emit('input', v); }
    }
  }  
}
</script>
<style scoped>
    select { width: 100%; }
</style>